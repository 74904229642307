@font-face {
  font-family: 'Museo Sans';
  src: url('../public/assets/MuseoSans-100.otf'),
  url('../public/assets/MuseoSans_500.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'boldSans';
  src: url('../public/assets/MuseoSans_500.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

:root {
  --silver-30: #F6F8FA;
  --main-dark-blue-90: #4D5575;
  --gray-main: #5B6670;
  --font-family: 'Museo Sans', sans-serif;
  --vlmx-navy-80: #4D5575;
  --main-dark-blue-100: #202A52;
  --vlmx-navy-05: #F4F4F6;
  --secondary-cyan-100: #41BBC9;
  --main-dark-blue-70: #797F97;
  --blue-calculadora: #1F3158;
}

.colorFondo{
  border-radius: 50px;
}

.colorFondoPercent{
  border-radius: 80px;
  width: 20px !important;
  height: 10px !important;
  position: absolute !important;
  margin-left: 1rem !important;
  margin-top: 5px !important;
}

body {
  font-family: 'Museo Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

.bodyLogin{
  background-image: url('../public/assets/fondoLogin.svg');
  background-size: 100%;
}

.bodyUser{
  height: 100%;
  background: linear-gradient(180deg, rgba(123, 126, 127, 0) 0%, rgba(235, 246, 251, 0.70) 100%) !important;
}

.pl-0{
  padding-left: 0px !important;
}

.noPadding{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ml-10{
  margin-left: 10px !important;
}

.mb-0{
  margin-bottom: 0px !important;
}
.mt-0{
  margin-top: 0px !important;
}

.ft-8{
  font-size: 8px !important;
}
.ft-10{
  font-size: 10px !important;
}

.ft-11{
  font-size: 11px !important;
}

.ft-12{
  font-size: 12px !important;
}

.ft-13{
  font-size: 13px !important;
}

.ft-14 {
  font-size: 14px !important;
}

.ft-15 {
  font-size: 15px !important;
}

.ft-16 {
  font-size: 16px !important;
}

.ft-18{
  font-size: 18px !important;
}

.ft-20 {
  font-size: 20px !important;
}

.ft-24 {
  font-size: 24px !important;
}

.ft-28{
  font-size: 28px !important;
}

.ft-32{
  font-size: 32px !important;
}

.ft-40{
  font-size: 40px !important;
}

.fw-300{
  font-weight: 400 !important;
}
.fw-400{
  font-weight: 400 !important;
}

.fw-600{
  font-weight: 600 !important;
}

.btn {
  font-family: 'Museo Sans', sans-serif;
}

.btn.disabled,
.disabled.btn-large,
.disabled.btn-small,
.btn-floating.disabled,
.btn-large.disabled,
.btn-small.disabled,
.btn-flat.disabled,
.btn:disabled,
.btn-large:disabled,
.btn-small:disabled,
.btn-floating:disabled,
.btn-large:disabled,
.btn-small:disabled,
.btn-flat:disabled,
.btn[disabled],
.btn-large[disabled],
.btn-small[disabled],
.btn-floating[disabled],
.btn-large[disabled],
.btn-small[disabled],
.btn-flat[disabled] {
  pointer-events: none !important;
  background-color: #D2D4DC !important;
  box-shadow: none !important;
  color: #FFFFFF !important;
  cursor: default !important;
  opacity: 1 !important;
}

input:not([type]),
input[type=text]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=url]:not(.browser-default),
input[type=time]:not(.browser-default),
input[type=date]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=search]:not(.browser-default),
textarea.materialize-textarea {
  background-color: var(--silver-30, #F6F8FA);
  border-radius: 8px 8px 0px 0px;
  height: 48px !important;
  padding-left: 8px !important;
  padding-right: 10px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.input-field>label {
  margin-left: 12px !important;
  transform: translateY(20px) !important;
}

.input-field>label:not(.label-icon).active {
  transform: translateY(0px) scale(0.8) !important;
}

button, input, optgroup, select, textarea {
  font-family: var(--font-family)
}

.labelNavy{
  color: var(--vlmx-navy-80, #4D5575)  !important;
}

.labelBlueCalc{
  color: var(--blue-calculadora, #1F3158)  !important;
}

.primaryText{
  color: #009BBA !important
}

.txt_main-dark-blue{
  color: #202A52  !important;
}

.btnMenu{
  height: auto !important;
  line-height: 18px !important;
}

.No_txtDeco{
  text-decoration: none !important;
}

.no_txtTransform{
  text-transform: none !important;
}



.modal-overlay{
  background: #0c0b0b8c !important;
  opacity: 1.0 !important;
}

.txt_rebalanceo:focus {
  border-bottom: 1px solid #0069A7 !important;
  box-shadow: 0 1px 0 0 #0069A7 !important;
  background: #EEF8F9 !important;
}

.txt_rebalanceo.active {
  border-bottom: 1px solid #0069A7 !important;
  box-shadow: 0 1px 0 0 #0069A7 !important;
  background: #EEF8F9 !important;
}

.txt_rebalanceo.errorVal {
  border-bottom: 1px solid red !important;
  box-shadow: 0 1px 0 0 red !important;
  background: #FDECEC !important;
}

.pdr-14{
  padding-right: 14% !important;
}


.pdl-16{
  padding-left: 16% !important;
}

.containerResumenBlue{
  background-color: #46c3d1 !important ;
  color: white;
  font-weight: bold;
  height: 40px;
  padding-top: 7px !important;
  border-right: 1px solid white;
}

.containerResumenBluePP{
  background-color: #70a1df !important ;
  color: white;
  font-weight: bold;
  height: 40px;
  padding-top: 7px !important;
  border-right: 1px solid white;
}


.containerResumenBlueFondoPP{
  background-color: #70a1df !important ;
  color: white;
  font-weight: bold;
  height: 25px;
  padding-top: 3px !important;
  border-right: 1px solid white;
}

.containerResumenBlueFondo{
  background-color: #46c3d1 !important ;
  color: white;
  font-weight: bold;
  height: 25px;
  padding-top: 3px !important;
  border-right: 1px solid white;
}

.containerResumenBlueDesc{
  background-color: #e9f8fa !important ;
  color: black;
  font-weight: bold;
  height: 45px;
  padding-top: 10px !important;
  border-right: 1px solid white;
  line-height: 1;
}

.containerResumenBlueDescPP{
  background-color: #eef4fd !important ;
  color: black;
  font-weight: bold;
  height: 45px;
  padding-top: 10px !important;
  border-right: 1px solid white;
  line-height: 1;
}


.containerResumenBlueDescFondoPP{
  background-color: #eef4fd !important ;
  color: black;
  font-weight: bold;
  height: 35px;
  padding-top: 10px !important;
  border-right: 1px solid white;
  line-height: 1;
}


.containerResumenBlueDescFondo{
  background-color: #e9f8fa !important ;
  color: black;
  font-weight: bold;
  height: 35px;
  padding-top: 10px !important;
  border-right: 1px solid white;
  line-height: 1;
}

.toastmessage_error{
   background: #F27468 !important;
   color: white;
   border-radius: 8px !important;
   height: 40px !important;
   padding-top: 8px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  padding-bottom: 8px !important;
}

.toastmessage_exito{
  background: #86CB7B !important;  
  color: white;
  border-radius: 8px !important;
  height: 40px !important;
  padding-top: 8px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  padding-bottom: 8px !important;
}


.containerResumenR{
  background-color: #ff606b !important ;
  color: white;
  font-weight: bold;
  height: 40px;
  padding-top: 7px !important;
}

.containerResumenRDesc{
  background-color: #ffeaea !important ;
  color: black;
  font-weight: bold;
  height: 45px;
  padding-top: 10px !important;
}


.tituloNota{
  color: #7f7f7f !important;
  font-size: 9px;
}

.notaText{
  color: #959595 !important; 
  font-size: 9px;
  text-align: justify;
}

.cardVF{
  max-width: 448px !important; 
  padding: 24px !important; 
  border: 1px solid !important; 
  border-radius: 8px !important; 
  border-color: #D2D4DC !important; 
  background-color: #FBFBFB !important; 
}

.cardVF.efectivo {
  background-color: #D8DEE3 !important;
}

.cardVF.efectivo .divider {
  background-color: #4D5575 !important;
}

.cardVFResaltar{
  font-size: 14px !important; 
  color: #4D5575 !important; 
  font-weight: bold !important; 
}

.txt_rebalanceo_pa:disabled {
  background: #D8DEE3 !important;
  color: #5B6670 !important;
  text-align: right !important;
}

.boldSans{
  font-family: boldSans;
}

.iconCancelar{
  margin-top: -3px !important;
  height: 28px !important;
  margin-left: 6px !important;
  margin-right: 10px !important;
}

.containerSuccess{
  border-radius: 8px !important;
  border: 1px solid !important;
  border-color: #EFEFEF !important;
  padding: 60px !important;
  background-color: white !important;
}


#modalSolicitudPrevia{
  max-width: 490px !important;
}

.modalRebalanceo{
  max-width: 490px !important;
}

.txtMainDB{
  color: #797F97 !important;
}