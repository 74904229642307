.linksC {
  color: #797F97 !important;
}

.labelTxt {
  color: var(--main-dark-blue-70, #797F97);
  font-family: 'Museo Sans', sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 16px !important;
}

.wrappSMovimiento {
  border-radius: var(--24, 24px) !important;
  background: #F6F8FA !important;
  padding: 16px 12px !important;
}


.loginForm {
  margin-top: 8% !important;
}

.btnNotification {
  border-radius: 10px !important;
}

.wrapperLogin {
  border-radius: 8px;
  padding: 5% !important;
}

.wrapperOlvide {
  border-radius: 8px;
  padding: 10% !important;
}

.bodyNot {
  line-height: 1.5 !important;
}

.wrapperOlvide {
  border-radius: 8px;
  padding: 6% !important;
  max-width: 560px !important;
}

.wrapperOlvide .valign-wrapper {
  margin-left: 15px !important;
}

.w-100 {
  width: 100%;
}

.btn-webSi-primary {
  background-color: #41BBC9 !important;
  color: #FFFFFF !important;
  text-transform: capitalize !important;
  width: 90% !important;
  border-radius: 8px !important;
  font-weight: bold !important;
  font-size: 16px !important;
}

span.field-icon {
  float: right;
  position: absolute;
  right: 20px;
  top: 13px;
  cursor: pointer;
  z-index: 2;
  color: #9e9e9e;
}

.boldText {
  font-weight: bold !important;
}

.inputToken {
  width: 27px !important;
  background-color: #d2eef3 !important;
  border-radius: 25px !important;
  padding-left: 15px !important;
  box-shadow: none !important;
  border-bottom: 0px !important;
}

.MuiTableRow-root {
  cursor: pointer !important;
}

.cursorPointer {
  cursor: pointer !important;
}

.conditionPass i {
  font-size: 16px;
  margin-top: 3.5px;
  margin-right: 5px !important;
}

.conditionPass.invalid {
  color: red;
}

.conditionPass.valid {
  color: green;
}

.mr-1{
  margin-right: 1rem !important;
}


/*Margenes*/
.mt-10px {
  margin-top: 10px !important;
}

.mt-8px {
  margin-top: 10px !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-16px {
  margin-bottom: 16px !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-05 {
  margin-top: 0.5 rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mt-3_5 {
  margin-top: 3.9rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mt-4_externo {
  margin-top: 4rem !important;
}

/*Paddings*/
.pt-1 {
  padding-top: 1rem !important;
}


.validationList {
  border: solid #e0e0e0 1.5px !important;
  border-radius: 10px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  padding-top: 10px !important;
}

#slide-detail {
  margin-top: 5rem !important;
  min-width: 400px !important;
}

.navHome {
  border-bottom: #F6F8FA solid 1px;
  height: 76px !important;
  padding: 16px 70px 12px 70px !important;
  margin-bottom: 0px !important;
}

.optMenu {
  border-radius: 10px !important;
  text-transform: none !important;
  font-size: 14px !important;
  font-weight: 300 !important;
}

.optMenu:hover {
  background-color: #c8eaee40;
}

/*Botones Activos*/
.btnBalances {
  background-color: #c8eaee40 !important;
}

.btnFondoAhorro {
  background-color: #FF5100 !important;
  color: white !important;
  cursor: pointer !important;
}

.btnCajaAhorro {
  background-color: #A25EB5 !important;
  color: white !important;
  cursor: pointer !important;
}

.btnPlanPension {
  background-color: #202A52 !important;
  color: white !important;
  cursor: pointer !important;
}

/*Cards mis balances*/

.tipo_aportacion {
  border-radius: var(--8, 8px) !important;
  background: #F6F8FA !important;
  padding: var(--8, 8px) 8px !important;
  margin-top: 10px !important;
}

.card_fondo {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.tipo_normal {
  border-radius: var(--8, 8px) !important;
  background: #F6F8FA !important;
  padding: var(--8, 8px) 8px !important;
  margin-top: 10px !important;
}

.linkCustom {
  color: var(--main-dark-blue-70, #797F97) !important;
  font-family: Museo Sans !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 16px !important;
  /* 100% */
  text-decoration-line: underline !important;
  cursor: pointer;
}

.backButton {
  border-radius: var(--12, 12px) !important;
  border: 1px solid var(--surface-cyan-200, #D8EDF8) !important;
  background: #FFF !important;
  width: 200px !important;
  padding: 12px var(--16, 16px) !important;
  justify-content: center !important;
  align-items: center !important;
  gap: var(--8, 8px) !important;
  cursor: pointer;
}

.btnContinue {
  border-radius: var(--12, 12px) !important;
  background: #343F6B !important;
  width: 200px !important;
  padding: 12px var(--16, 16px) !important;
  justify-content: center !important;
  align-items: center !important;
  gap: var(--8, 8px) !important;
  color: #FFFFFF !important;
  cursor: pointer;
}

.btnContinueF5 {
  border-radius: var(--8, 8px) !important;
  background: #343F6B !important;
  width: 200px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: var(--8, 8px) !important;
  color: #FFFFFF !important;
  cursor: pointer;
  text-transform: none !important;
  font-weight: bold;
}

.btn.btnSecondary {
  border-radius: 8px !important;
  border: 1px solid #41BBC9 !important;
  background: #FFF !important;
  color: #41BBC9 !important;
  text-transform: none !important;
  font-size: 14px !important;
}

.btn.btnSecondaryb {
  border-radius: 8px !important;
  border: 1px solid #41BBC9 !important;
  background: #FFF !important;
  color: black !important;
  text-transform: none !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.btn.btnSecondary:disabled {
  border: 1px solid #D2D4DC !important;
  background: #FFF !important;
  color: #D2D4DC !important;

}



.totalPI {
  background: #F6F8FA !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  margin-bottom: 0px !important;
}

.tipo_prestamo {
  border-radius: var(--8, 8px) !important;
  background: rgba(225, 37, 27, 0.08) !important;
  padding: var(--8, 8px) 8px !important;
  margin-top: 10px !important;
}

.tipo_negativo {
  border-radius: var(--8, 8px) !important;
  background: rgba(225, 37, 27, 0.08) !important;
  padding: var(--8, 8px) 8px !important;
  margin-top: 10px !important;
}


.cardSaldoActual {
  background: var(--surface-grey-100, #F6F8FA) !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
  color: #4D5575 !important;
  font-weight: bold !important;
  padding: 8px !important;
}

.colorDarkBlue {
  color: #4D5575 !important;
}

.cardSaldActualtotal {
  border-radius: var(--8, 8px);
  background: var(--surface-cyan-100, #EBF6FB) !important;
  color: #202A52 !important;
  font-weight: bold !important;
  padding: 8px !important;
}

.listItems {
  border-bottom: solid;
  border-left: solid;
  border-right: solid;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.chartInfo {
  background: white !important;
  border: solid 0.5px !important;
}

.infoIRow:hover,
.infoIRow.active {
  background-color: #41bbc917 !important;
  color: #41bbc9 !important;
  cursor: pointer;
}

.lefTitem {
  color: #4D5575 !important;
  font-weight: 300 !important;
}

.vlmx-navy-80 {
  color: #4D5575;
}

.closeSlidePrestamoSelected {
  position: absolute !important;
  margin-top: 12px !important;
}

.titlePrestamoSelected {
  padding-left: 22px !important;
  padding-right: 22px !important;
  font-weight: bold;
  font-size: 16px !important;
}

.ammount {
  color: #202A52 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.listItems div {
  padding-top: 2px !important;
  height: 31.6px !important;
}

.cardBalance {
  margin: initial !important;
  border-radius: 10px;
  color: white;
  padding: 12.659px !important;
}

.listItems.fondosAhorroCard {
  border-color: #FF5100;
}

.listItems.cajaAhorroCard {
  border-color: #6C3175;
}

.listItems.planPensionCard {
  border-color: #202A52;
}

.wrapperBanner {
  background-image: url('./assets/cardBanner.svg') !important;
  background-repeat: no-repeat !important;
}

.bannerTxt {
  padding-top: 44px !important;
  padding-left: 41px !important;
  padding-bottom: 38px !important;
  max-width: 340px !important;
}

.bannerContent {
  background-image: url('./assets/banner2.svg') !important;
  background-repeat: no-repeat !important;
  background-size: cover;
  height: 342px !important;
}

.cardBalance.fondosAhorroCard {
  background-image: url('./assets/cardFA.svg') !important;
  background-repeat: no-repeat !important;
  background-size: cover;
}

.cardBalance.cajaAhorroCard {
  background-image: url('./assets/cardCA.svg') !important;
  background-repeat: no-repeat !important;
  background-size: cover;
}

.cardBalance.planPensionCard {
  background-image: url('./assets/cardPA.svg') !important;
  background-repeat: no-repeat !important;
  background-size: cover;
}


.cantidad {
  font-size: 18px;
}

.badgeCard {
  background: #37c137;
  font-size: 11.077px !important;
  border-radius: 6.33px !important;
  color: #202A52 !important;

}



.wrapperListItems {
  padding-right: 12px !important;
  padding-left: 12px !important;
  margin-top: -7px !important;
}


.secondaty_cyan {
  color: var(--secondary-cyan-100, #41BBC9) !important;
}

.bgGray {
  background: var(--vlmx-navy-05, #F4F4F6);
}


/*customizados*/
.modalTC {
  border-radius: 8px !important;
  width: 85% !important;
}

.modalTCContent {
  padding: 0px !important;
}

#halfDonut {
  margin-top: -100px !important;
}

.css-ju3thd {
  font-family: 'Museo Sans', sans-serif !important;
}

.MuiTableCell-root {
  font-family: 'Museo Sans', sans-serif !important;
}

.modal-overlay {
  backdrop-filter: blur(11px) !important;
}

.modal_loader {
  box-shadow: none !important;
  background-color: #fafafa00 !important;
  width: 100% !important;
  height: 100%;
  top: 0% !important;
  padding-top: 10% !important;
  backdrop-filter: blur(12px) !important;
  max-height: 100% !important;
}

.optAct {
  border-radius: 32px !important;
  border: 1px solid #B4D2E1 !important;
  background: var(--surface-cyan-200, #D8EDF8) !important;
}

.helpCalc {
  color: #A6AABA !important;
  font-size: 24px !important;
  margin-top: 1rem !important;
  cursor: pointer;
}

.wrapperGraficaCalc {
  border: 1px solid var(--sureface-sky-200, #D8EDF8) !important;
  border-radius: 8px !important;
}

.rowResultCalc {
  padding: 12px 8px 12px 8px !important;
}

.cardSA73 {
  background-color: #CBABCE !important;
}

.cardCA73 {
  background-color: #A25EB5 !important;
}

.cardSA97 {
  background-color: #FFD15A !important;
}

.cardCA97 {
  background-color: #FFA400 !important;
}


.btnAction {
  background-color: #343F6B !important;
  border-radius: 12px !important;
  text-transform: none !important;
  color: #FFFFFF !important;
}

.btnActionB {
  background-color: #343F6B !important;
  border-radius: 12px !important;
  text-transform: none !important;
  color: #FFFFFF !important;
  font-weight: 600 !important;
}

.btnAction-14 {
  background-color: #343F6B !important;
  border-radius: 12px !important;
  text-transform: none !important;
  color: #FFFFFF !important;
}

.paddingEduFin {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.btnCalculoRetiro {
  margin-top: 1.5rem !important;
  cursor: pointer;
}

.wrapperEduFin {
  padding: 12px 14px 24px 12px !important;
  background: white !important;
  border-radius: 8px !important;
  border: 1px solid #F4F4F5 !important;
}

.wrapperFormMovimientos {
  border-radius: 8px !important;
  border: 1px solid #EFEFEF  !important;
  box-shadow: 0px 4px 4px 0px rgba(216, 222, 227, 0.25) !important;
  
  /* padding-left: 22px !important;
    padding-right: 22px !important; */
  padding: 22px;
}

.btnPrestamo {
  background: #343F6B !important;
  color: #FFF !important;
  font-size: 14px !important;
  border-radius: 12px !important;
  font-weight: bold !important;
  text-transform: none !important;
}

.rowPrestamoActual {
  background: #F6EBFF !important;
  border-radius: 8px !important;
  padding: 8px !important;
  color: #4D5575 !important;
}

.rowPrestamoActivo {
  background: #F6F8FA !important;
  padding: 8px !important;
  color: #4D5575 !important;
  border: 1px solid #E4E4E4 !important;
}

.borderUltimoP {
  border: solid 1px #AAD5DA !important;
  border-radius: 20px;
}

.badgePlazos {
  background: #41BBC9;
  color: white;
  border-radius: 20px;
}

.borderLeftUP {
  border-left: solid 1px #AAD5DA !important;
}

.capitalPendiente {
  background: #DEEFF1 !important;
}

.badgePrestamoLiquidado {
  background: #F6F8FA !important;
  border-radius: 8px !important;
  color: #059669 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.badgePrestamoActivo {
  background: #DDF7FA !important;
  border-radius: 8px !important;
  color: #0891B2 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.badgePrestamoActual {
  background: #F4CDFF !important;
  border-radius: 8px !important;
  color: #6C3175 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.mw-200 {
  max-width: 200px !important;
}

.pl-54_externo {
  padding-left: 54px !important;
}

.txt_transformNone {
  text-transform: none !important;
}

.paddingLeftBotones {
  padding-left: 12px !important;
}

.paddingLeft0 {
  padding-left: 0 !important;
}

.margenBotonesProd {
  margin-bottom: 12px !important;
  margin-top: 12px !important;
}

.botonesProductos {
  background: #F6F8FA !important;
  border-radius: 24px !important;
}

.infoFondo {
  background: #F6F8FA !important;
  border-radius: 8px !important;
  color: #202A52 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding: 5px !important;
  margin-bottom: 8px !important;
}

.hoverGray:hover {
  background-color: #F6F8FA !important;
}

.grayBg {
  background: #dadee2 !important;
}

.mw-100 {
  max-width: 100px !important;
}

.ln1_5 {
  line-height: 1.5 !important;
}

.txtBold {
  font-weight: bold !important;
}

.contentLegal {
  white-space: pre-wrap !important;
  max-width: 100%;
  text-align: justify !important;
}

.wPP .input-field {
  /*Wrapper Perfil Pass para cambio de contraseña*/
  max-width: 96% !important;
}

.react-switch-handle {
  border: solid !important;
}

.colSecurity {
  max-width: 96% m !important;
  border-radius: 8px !important;
  border: 1px solid #F4F4F5 !important;
}

.colIS {
  padding: 24px !important;
  border-bottom: 1px solid #F4F4F5 !important;
}

.btnSide {
  line-height: 3.5 !important;
  border-right: 1px solid #D1E3EE !important;
  min-height: 500px;

}

.wrapDataProfile {
  background: #fff;
  border: solid #F4F4F5;
  /* border-radius: 8px !important; */
  margin-left: 10px !important;
  line-height: 0.5;
  max-width: 90% !important;
}


.wrapperSaldoAlaFecha {
  margin-left: 8px !important;
  margin-right: 8px !important;
}


.border-gray {
  border: solid #F4F4F5;
}

.br-8 {
  border-radius: 8px !important;
}

.br-12 {
  border-radius: 12px !important;
}

.noBoxShadow {
  box-shadow: none !important;
}

.wrapDataLegales {
  background: #fff;
  border: solid #F4F4F5;
  /* border-radius: 8px !important; */
  margin-left: 10px !important;
  max-width: 90% !important;
  padding: 24px !important;
}

.wrapDataAccounts {
  background: #fff;
  border: solid #F4F4F5;
  /* border-radius: 8px !important; */
  margin-left: 10px !important;
  max-width: 90% !important;
  padding: 10px !important;
  border-radius: 8px !important;
}

.p-10 {
  padding: 10px !important;
}

.contentInfoAccount {
  background: #F6F8FA;
  border-radius: 8px !important;
  margin-left: 10px !important;
  max-width: 96% !important;
  margin-top: 10px !important;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}


.brT {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.brB {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.notification:hover {
  background-color: #F4F4F6;
}

.sidenav-overlay {
  display: none !important;
}

.mt-n-2 {
  margin-top: -2px !important;
}

.mb-n-8 {
  margin-bottom: -8px !important;
}

.chProfile {
  position: absolute !important;
  margin-top: 7px !important;
  margin-left: 5px !important;
}

#userNav {
  height: auto;
  margin-top: 4rem !important;
  padding-bottom: 4px !important;
  border-radius: 10px !important;
}

@media (max-height: 644px) {
  #userNav {
    margin-top: 0rem !important;
    padding-bottom: 4px !important;
    border-radius: 10px !important;
    height: 100vh !important;
  }
}



#userNav.sidenav li>a {
  padding: 0 12px !important;

}

#userNav.sidenav li>a>i,
.sidenav li>a>[class^="mdi-"],
.sidenav li>a li>a>[class*="mdi-"],
.sidenav li>a>i.material-icons {
  margin: 0 8px 0 0;
}

#userNot {
  margin-top: 4rem !important;
  height: auto !important;
  padding-bottom: 4px !important;
  border-radius: 10px !important;
}

#userNot.sidenav li>a {
  padding: 0 12px !important;

}

#userNot.sidenav li>a>i,
.sidenav li>a>[class^="mdi-"],
.sidenav li>a li>a>[class*="mdi-"],
.sidenav li>a>i.material-icons {
  margin: 0 10px 0 0;
}

.spanName {
  max-width: 50px !important;
}

.inputErr {
  background-color: #FFEDEF !important;
}

.lblErr {
  color: #EC626E !important;
}

.logoForgot {
  max-width: 70px !important;
}

.logoNav {
  height: 36px !important;
}

.avatarText {
  font-weight: 700 !important;
  font-size: 12px !important;
  color: #1B1C26 !important
}


.seeDetail {
  text-decoration: underline !important;
  cursor: pointer !important;
  padding-top: 5px !important;
  color: var(--vlmx-navy-80, #4D5575);
}

.no_txt_deco {
  text-decoration: none !important;
}

.wrapperNavButtons {
  max-height: 40px !important;
}

.carousel {
  height: 50px !important;
}

.carouselOptMenu.carousel.carousel-slider .carousel-item {
  height: auto !important;
  min-height: auto !important;
}


.btnAct {
  color: var(--main-dark-blue-90, #4D5575) !important;
  font-family: 'Museo Sans' !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: bold !important;
  line-height: 16px !important;
  text-transform: none !important;
  width: 100% !important;
  border-radius: var(--12, 12px) !important;
  border: 1px solid var(--surface-cyan-200, #D8EDF8) !important;
  background: #FFF !important;
  text-align: left !important;
  height: auto !important;
  padding: 12px !important;
}

.btnAct.optSelected {
  background: #EBF6FB !important;
}

.cIcon {
  margin-right: 12px !important;
}

.balancesContainer {
  min-height: 550px !important;
}

/*Medias queries*/

@media (min-width: 993px) {
  .containerLogin {
    margin-left: 6% !important;
  }
}

@media (max-width: 839px) {
  .navHome {
    padding: 16px 0px 12px 0px !important;
  }
}

@media (min-width: 995px) {
  .logoNav {
    display: block !important;
  }

  .logoNav_m {
    display: none !important;
  }
}

@media (max-width: 994px) {
  .logoNav {
    display: none !important;
  }

  .logoNav_m {
    display: block !important;
    height: 36px !important;
  }
}

@media (max-width: 992px) {
  .pl-54_externo {
    padding-left: 0px !important;
  }

  .mt-4_externo {
    margin-top: 2rem !important;
  }
}

@media only screen and (min-width: 993px) {
  .loginModule .container {
    width: 90% !important;
  }
}


/* Loader */
.loadingFR {
  width: 48px;
  height: 48px;
  border: 3px dotted #FFF;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

.loadingFR::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px dotted #4ebad3;
  border-style: solid solid dotted;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.btnCancel {
  text-transform: none !important;
}

.btnContinuarTC {
  width: 200px !important;
  border-radius: var(--12, 12px) !important;
  background: #343F6B !important;
  text-transform: none !important;
  font-weight: bold !important;
}

.btnRechazarTC {
  color: #202A52 !important;
  border-radius: var(--12, 12px) !important;
  border: 1px solid var(--surface-cyan-200, #D8EDF8) !important;
  background: #FFF !important;
  text-transform: none !important;
  font-weight: bold !important;
}

.txtJustify {
  text-align: justify !important;
}

.blueTextBold {
  color: #202A52 !important;
  font-weight: bold !important;

}

.pdLeft{
  padding-left: 1.5rem !important;
}

.borderCyan {
  border-bottom: solid 1px #D8EDF8 !important;
}

.container_inputRebalanceo {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.txt_rebalanceo {
  text-align: right !important;
}

.headerRebalanceo {
  background: var(--surface-grey-100, #F6F8FA) !important;
}

#pdfDownload{
  background-color: white !important;
}

.page-break {
  page-break-before: always  !important; /* Compatible con html2pdf */
  break-before: always  !important;
}

#modalNoContent{
  max-height: 74% !important;
}